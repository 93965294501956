import { datadogRum } from '@datadog/browser-rum';
import { usePathname } from 'next/navigation';
import { useEffect, useRef } from 'react';

const POLLING_INTERVAL = 1000 * 60 * 30; // 30 minutes
const BASE_PATH = process.env.NEXT_PUBLIC_BASE_PATH;
const APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION;

interface HealthResponse {
  info: {
    version: string;
  };
}

async function fetchVersion(): Promise<string> {
  if (!BASE_PATH) {
    throw new Error('Missing NEXT_PUBLIC_BASE_PATH environment variable');
  }

  const res = await fetch(`${BASE_PATH}/api/health`);

  if (!res.ok) {
    throw new Error(`Failed to fetch version: ${res.statusText}`);
  }

  const {
    info: { version },
  }: HealthResponse = await res.json();
  return version;
}

async function isUpdateAvailable(): Promise<boolean> {
  try {
    if (!APP_VERSION) {
      throw new Error('Missing NEXT_PUBLIC_APP_VERSION environment variable');
    }
    const version = await fetchVersion();
    return version !== APP_VERSION;
  } catch (err) {
    datadogRum.addError(err);
    return false;
  }
}

function createUpdateHandler(callback: () => void) {
  return async () => {
    if (await isUpdateAvailable()) {
      callback();
    }
  };
}

const checkVersionAndReload = createUpdateHandler(() => {
  window.location.reload();
});

export function useAppUpdateListener(onUpdateAvailable: () => void) {
  const pathname = usePathname();
  const shouldPoll = useRef(true);
  const pollIntervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    checkVersionAndReload();
  }, [pathname]);

  useEffect(() => {
    if (shouldPoll.current) {
      const pollForUpdates = createUpdateHandler(() => {
        onUpdateAvailable();
        if (pollIntervalRef.current) {
          clearInterval(pollIntervalRef.current);
        }
        shouldPoll.current = false;
      });

      pollIntervalRef.current = setInterval(pollForUpdates, POLLING_INTERVAL);
    }

    return () => {
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current);
      }
    };
  }, [onUpdateAvailable]);
}
