import { useCallback, useState } from 'react';
import { useQueryParams } from 'src/utils/use-query-params';
import { useTrackEvent, AnalyticsEvent } from 'src/modules/analytics';

export const MAX_SNOOZE_COUNT = 3;
export const SNOOZE_DELAY_MS = 30 * 60 * 1000; // Thirty minutes
export const NOTIFICATION_DELAY_MS = 12 * 60 * 60 * 1000; // 12 hours

export interface AppRefreshModalProps {
  isOpen: boolean;
  allowSnooze: boolean;
  onSnooze: () => void;
  onRefresh: () => void;
  onExited: () => void;
}

interface UseAppRefreshModalReturn extends AppRefreshModalProps {
  onUpdateAvailable: () => void;
}

export function useAppRefreshModal(): UseAppRefreshModalReturn {
  const [isOpen, setIsOpen] = useState(false);
  const [snoozeCount, setSnoozeCount] = useState(0);
  const allowSnooze = snoozeCount < MAX_SNOOZE_COUNT;
  const { addQueryParam, removeQueryParam } = useQueryParams();
  const trackEvent = useTrackEvent();

  const open = useCallback(() => {
    setIsOpen(true);

    addQueryParam('modal', 'app_version_refresh');

    trackEvent(AnalyticsEvent.AppRefreshModalMount, {
      isSnoozeEnabled: allowSnooze,
    });
  }, [allowSnooze, addQueryParam, trackEvent]);

  const close = useCallback(() => {
    setIsOpen(false);
    removeQueryParam('modal');
  }, [removeQueryParam]);

  const onSnooze = useCallback(() => {
    close();
    trackEvent(AnalyticsEvent.AppRefreshModalSnoozeClick);
    setTimeout(open, SNOOZE_DELAY_MS);
  }, [open, close, trackEvent]);

  // Increment snooze count after exit animation completes. Prevents
  // a flash of updated dialog content before it closes.
  const onExited = useCallback(() => {
    setSnoozeCount((prevCount) => prevCount + 1);
  }, []);

  const onRefresh = useCallback(async () => {
    trackEvent(AnalyticsEvent.AppRefreshModalRefreshClick);
    await removeQueryParam('modal');
    window.location.reload();
  }, [removeQueryParam, trackEvent]);

  const onUpdateAvailable = useCallback(() => {
    setTimeout(open, NOTIFICATION_DELAY_MS);
  }, [open]);

  return {
    isOpen,
    allowSnooze,
    onSnooze,
    onExited,
    onRefresh,
    onUpdateAvailable,
  };
}
