import { useAppUpdateListener } from './useAppUpdateListener';
import { useAppRefreshModal } from './useAppRefreshModal';
import { AppRefreshModalView } from './AppRefreshModalView';

export function AppRefreshModal() {
  const { onUpdateAvailable, ...props } = useAppRefreshModal();

  useAppUpdateListener(onUpdateAvailable);

  return <AppRefreshModalView {...props} />;
}
