import { Stack, Typography } from '@mui/material';
import { AutoFixHigh, Warning } from '@mui/icons-material';
import {
  Dialog,
  DialogActionsWithButtons,
  DialogContent,
  Show,
} from '@side/pantry';
import type { AppRefreshModalProps } from './useAppRefreshModal';

export function AppRefreshModalView({
  isOpen,
  allowSnooze,
  onSnooze,
  onExited,
  onRefresh,
}: AppRefreshModalProps) {
  return (
    <Dialog open={isOpen} maxWidth="sm" TransitionProps={{ onExited }}>
      <DialogContent
        title={
          <Stack direction="row" alignItems="center">
            <Show
              when={allowSnooze}
              fallback={
                <>
                  <Warning sx={{ color: 'warning.main', mr: 1 }} /> New Side App
                  Version Required
                </>
              }
            >
              <AutoFixHigh sx={{ color: 'secondary.main', mr: 1 }} /> New Side
              App Version Available
            </Show>
          </Stack>
        }
      >
        <Typography>
          <Show
            when={allowSnooze}
            fallback="The version of the Side app you are using is outdated. To continue using the app you must refresh the page to update to the latest version."
          >
            Save any work in progress before refreshing to the newest app
            version
          </Show>
        </Typography>
      </DialogContent>

      <DialogActionsWithButtons
        CancelButtonProps={
          allowSnooze
            ? {
                buttonText: 'Snooze (30 Minutes)',
                onClick: onSnooze,
              }
            : undefined
        }
        SubmitButtonProps={{
          defaultText: 'Refresh to Update',
          onClick: onRefresh,
        }}
      />
    </Dialog>
  );
}
