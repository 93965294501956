import { useCallback } from 'react';
import { useRouter as usePagesRouter } from 'next/compat/router';
import { useRouter, useSearchParams } from 'next/navigation';

/**
 * A custom hook to manage query parameters in the URL.
 * @returns {object} - Methods to add, remove, and set query parameters.
 */
export const useQueryParams = () => {
  const pagesRouter = usePagesRouter();
  const appRouter = useRouter();
  const searchParams = useSearchParams();

  /**
   * Adds or updates a query parameter in the URL.
   * @param {string} key - The key of the query parameter.
   * @param {string} value - The value of the query parameter.
   */
  const addQueryParam = useCallback(
    (key: string, value: string) => {
      if (pagesRouter) {
        const query = { ...pagesRouter.query, [key]: value };
        pagesRouter.replace({ query }, undefined, {
          scroll: false,
          shallow: true,
        });
      } else if (appRouter && searchParams) {
        const params = new URLSearchParams(searchParams);
        params.set(key, value);
        appRouter.replace(`?${params.toString()}`, { scroll: false });
      }
    },
    [appRouter, pagesRouter, searchParams],
  );

  /**
   * Removes a query parameter from the URL.
   * @param {string} key - The key of the query parameter to remove.
   */
  const removeQueryParam = useCallback(
    async (key: string) => {
      if (pagesRouter) {
        const { [key]: _, ...rest } = pagesRouter.query;
        await pagesRouter.replace({ query: rest }, undefined, {
          scroll: false,
          shallow: true,
        });
      } else if (appRouter && searchParams) {
        const params = new URLSearchParams(searchParams);
        params.delete(key);
        appRouter.replace(`?${params.toString()}`, { scroll: false });
      }
    },
    [appRouter, pagesRouter, searchParams],
  );

  /**
   * Replaces the query parameters with the provided object.
   * @param {object} newParams - An object representing the new query parameters.
   */
  const setQueryParams = useCallback(
    (newParams: Record<string, string>) => {
      if (pagesRouter) {
        pagesRouter.replace({ query: newParams }, undefined, {
          scroll: false,
          shallow: true,
        });
      } else if (appRouter) {
        const params = new URLSearchParams(newParams);
        appRouter.replace(`?${params.toString()}`, { scroll: false });
      }
    },
    [appRouter, pagesRouter],
  );

  return { addQueryParam, removeQueryParam, setQueryParams };
};
